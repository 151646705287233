.line-gradient {
  background: repeating-linear-gradient(
    to bottom,
    #2999FF 0px,
    #2999FF 10px,
    transparent 10px,
    transparent 20px
  );
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0) 100%);
  -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0) 100%);
  background-size: 3px 20px; /* 점선 크기 조절 */
  background-repeat: repeat-y;
}
